<template>
  <div>
    <div class="imgbox">
      <!-- <img src="https://qn.icgushi.com/NANJIXIONGLIAN%3ABANNER.png" alt="" /> -->
      <img src=" https://qn.icgushi.com/NANJIXIONG/LIAN.png" alt="" />
    </div>
    <!-- 联系我们 -->
    <div class="contactUS">
      <div class="title">联系南极熊</div>
      <div class="ereabox">
        <div class="erweima">
          <img src="https://qn.icgushi.com/NAN/B.png" alt="" class="imger" />
          <div class="ertt">联系我们</div>
        </div>
        <div class="emailAddress">
          <div v-for="item in contactList" :key="item.title" class="eabox">
            <img :src="item.imgurl" alt="" class="eaimg" />
            <div class="eatitle">{{ item.title }}</div>
            <div class="eatext">
              <p v-for="(item, index) in item.text" :key="index">{{ item }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactList: [
        {
          imgurl: "https://qn.icgushi.com/NANJIXIONGLIAN%3ADIZHI.png",
          title: "地址",
          text: ["深圳市龙岗区龙城街道回龙埔社区3121"],
        },
        {
          imgurl: "https://qn.icgushi.com/NANJIXIONGLIAN%3ADIANHUA.png",
          title: "电话",
          text: [
            "手机：131-4994-1968",
            "固话：0755-84502267",
            "传真：0755-84506395",
          ],
        },
        {
          imgurl: "https://qn.icgushi.com/NANJIXIONGLIAN%3AYOUJIAN.png",
          title: "邮件",
          text: ["sale001@amebear.com"],
        },
      ],
    };
  },
};
</script>

<style scoped lang='scss'>
.imgbox {
  width: 100%;
  height: 480px;
}
.contactUS {
  width: 1320px;
  height: 600px;
  margin: 0 auto;
  .title {
    width: 1320px;
    text-align: center;
    font-size: 34px;
    font-family: PingFangSC-Medium-, PingFangSC-Medium;
    font-weight: normal;
    color: #333333;
    margin-top: 100px;
    margin-bottom: 98px;
  }
  .ereabox {
    width: 1320px;
    height: 350px;
    display: flex;
    justify-content: space-between;
    // border: 1px solid green;
    .erweima {
      width: 250px;
      height: 350px;
      // border: 1px solid red;
      .imger {
        width: 250px;
        height: 250px;
      }
      .ertt {
        width: 250px;
        margin-top: 39px;
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
        // font-weight: normal;
        color: #333333;
      }
    }
    .emailAddress {
      width: 770px;
      height: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .eabox {
        width: 200px;
        height: 350px;
         text-align: center;
        .eaimg {
          width: 160px;
          height: 160px;
          
        }
        .eatitle {
          width: 160px;
         text-align: center;
          font-size: 30px;
          font-family: PingFangSC-Regular-, PingFangSC-Regular;
          font-weight: normal;
          color: #333333;
          margin: 50px auto 19px;
          // margin-top: 50px;
          // margin-bottom: 19px;
        }
        .eatext {
          width: 200px;
          text-align: center;
          font-size: 16px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #707070;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
